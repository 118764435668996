import { useRef, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * ContentEditable Component
 *
 * A controlled content-editable component that supports inline editing,
 * placeholder text, and event handling for confirmation or cancellation
 * of changes. It is designed to be reusable with props for customization.
 *
 * Props:
 * - `value` (string): The current value of the editable content.
 * - `onChange` (function): Callback triggered when the content is confirmed to have changed.
 * - `placeholder` (string): Placeholder text shown when the content is empty.
 * - `disabled` (bool): If true, the component renders as non-editable text.
 * - `className` (string): Additional CSS classes for styling the component.
 *
 * Features:
 * - Supports Enter (to confirm changes) and Escape (to cancel changes) keyboard shortcuts.
 * - Automatically manages loading state while awaiting the `onChange` callback.
 * - Allows for graceful fallback to a non-editable paragraph when disabled.
 */
export default function ContentEditable({
  value = '',
  onChange,
  placeholder = '',
  disabled = false,
  className = '',
}) {
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const editableRef = useRef()

  async function onConfirm() {
    const newValue = editableRef.current.textContent.trim()
    setEditing(false)
    if (newValue === value) return
    setLoading(true)
    try {
      await onChange(newValue)
    } catch {
      /* ignore errors */
    }
    setLoading(false)
  }

  function onCancel() {
    setEditing(false)
    editableRef.current.textContent = value
    editableRef.current.blur()
  }

  function onKeydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      onConfirm()
      editableRef.current.blur()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      onCancel()
    }
  }

  function handleFocus() {
    setEditing(true)
  }

  function handleBlur() {
    onConfirm()
  }

  if (disabled) {
    return (
      <p className={`px-1 text-left ${className}`}>
        {value}
      </p>
    )
  }

  return (
    <div className="relative w-full">
      <div
        ref={editableRef}
        contentEditable={!disabled}
        suppressContentEditableWarning
        className={[
          'px-1 w-full bg-transparent rounded text-left cursor-text focus:outline-none focus:ring focus:ring-blue-500/50',
          loading ? 'bg-animated from-gray-800/50 to-gray-600/50' : '',
          className,
        ].join(' ')}
        onInput={() => setEditing(true)}
        onBlur={handleBlur}
        onKeyDown={onKeydown}
        onClick={handleFocus}
        onFocus={handleFocus}
      >
        {value}
      </div>
      {!value && !editing && !loading && (
        <span className="absolute top-0 left-0 px-1 text-white/50 pointer-events-none hover:opacity-100 group-hover:opacity-100 opacity-0 transition-opacity">
          {placeholder}
        </span>
      )}
    </div>
  )
}

ContentEditable.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
