import { useAtomValue } from 'jotai'
import PropTypes from 'prop-types'
import { getTimestampByCueIdAtom } from '../../../store/timestamps.store'
import { cuesAtom, getPreviousCueAtom, nextHardStartCueIdAtom } from '../../../store/rundown.store'
import { CueStartMode } from '@rundown-studio/types'
import { addMilliseconds } from 'date-fns'
import floorMs from '../../../utils/floorMs.js'
import { formatCountdown, formatDurationHuman } from '../../../utils/formatTime'
import { formatTimeOfDay, isSameDay } from '@rundown-studio/timeutils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faWarning } from '@fortawesome/free-solid-svg-icons'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../interactives/Tooltip.jsx'

/**
 * BetweenCuesIndicator component
 *
 * This component displays the timing information and relationships between cues in the rundown.
 * It includes a fixed bottom bar with a live countdown to the next hard start cue and an actionable
 * button to scroll directly to the associated cue in the rundown.
 *
 * Props:
 * - cue (object): The cue object containing timing and metadata information. Required.
 * - timezone (string): The timezone to consider for formatting timestamps. Defaults to 'UTC'.
 *
 * Features:
 * - Displays the type of timing relationship (e.g., "Gap", "Overlap", "Crossing midnight", or "On time").
 * - Fixed bottom bar showing:
 *   - The timestamp of the next hard start cue in a human-readable format.
 *   - A live countdown to the next hard start cue, updating every second.
 *   - The type of timing relationship (e.g., Gap, Overlap, On time).
 *   - The title of the next hard start cue.
 * - Provides a scroll-to functionality to navigate smoothly to the next hard start cue.
 * - Utilizes icons and color-coded indicators for visual feedback on timing relationships.
 */
function BetweenCuesIndicator ({ cue, timezone = 'UTC' }) {
  // Atoms and derived state
  const getTimestampByCueId = useAtomValue(getTimestampByCueIdAtom)
  const getPreviousCueById = useAtomValue(getPreviousCueAtom)
  const nextHardStartCueId = useAtomValue(nextHardStartCueIdAtom)
  const cues = useAtomValue(cuesAtom)

  const [countdown, setCountdown] = useState(0)
  const scrollRef = useRef(null)

  const isNextHardStart = cue.id === nextHardStartCueId
  const nextHardCue = cues[nextHardStartCueId]

  const timestamp = getTimestampByCueId(cue.id)
  const previousCueTimestamp = getTimestampByCueId(getPreviousCueById(cue.id)?.id)
  const nextHardCueTimestamp = getTimestampByCueId(nextHardStartCueId)

  // Countdown update every 100ms.
  useEffect(() => {
    // TODO: replace Date.now() with adjusted serverTime
    const interval = setInterval(() => {
      setCountdown(nextHardCueTimestamp?.actual.start.getTime() - Date.now())
    }, 100)

    return () => clearInterval(interval)
  }, [nextHardCueTimestamp])

  // Memoized action functions (scroll and visibility)
  const scrollIntoView = useCallback(() => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [scrollRef])

  if (!timestamp || !previousCueTimestamp) return null

  // Calculates the end time of the previous cue and the time difference between it and the current cue's start time.
  // Formats the difference in milliseconds to a human-readable string or marks it as "On time" if there is no difference.
  const previousEndTime = addMilliseconds(previousCueTimestamp.actual.start, previousCueTimestamp.actual.duration)
  const differenceMs = floorMs(timestamp.actual.start).getTime() - floorMs(previousEndTime).getTime()
  const timeDiffStr = differenceMs == 0 ? 'On time' : formatDurationHuman(differenceMs)

  // Applies wording and style variables based on the time difference.
  let type = ''
  let text = ''
  let textColor = ''
  let bgColor = ''
  let icon = null

  if (cue.startMode === CueStartMode.FIXED) {
    if (differenceMs > 0) {
      // GAP
      type = 'Gap'
      text = `Gap of ${timeDiffStr}`
      textColor = 'text-green-600'
      bgColor = 'bg-green-400'
      icon = faClock
    } else if (differenceMs < 0) {
      // OVERLAP
      type = 'Overlap'
      text = `Overlap by ${timeDiffStr}`
      textColor = 'text-red-600'
      bgColor = 'bg-red-400'
      icon = faWarning
    }
  }

  // Adds "Crossing midnight" to the text if the timestamps are on different days.
  if (!isSameDay(timestamp.actual.start, previousCueTimestamp.actual.start, { timezone })) {
    if (!text) text = 'Crossing midnight'
    else text = `${text} and crossing midnight`
  }

  // Formats the timestamp of the next hard start cue into a human-readable time string.
  const nextHardStartStr = formatTimeOfDay(nextHardCueTimestamp?.actual.start || 0, { timezone, seconds: 'nonzero' })

  // Render the fixed bottom bar with the countdown, cue details, and
  // scroll-to-cue functionality if the cue is the next hard start cue.
  if (isNextHardStart) {
    return (
      <>
        <div ref={scrollRef} className="flex items-center gap-2 leading-5 text-xs my-0.5 w-fit text-gray-500 scroll-m-24">
          <span className={[textColor, 'relative flex h-3 w-3'].join(' ')}>
            <span className={['animate-ping absolute inline-flex h-full w-full rounded-full opacity-50', bgColor].join(' ')}></span>
            {icon && <FontAwesomeIcon icon={icon} />}
          </span>
          <span>{text}</span>
        </div>
        <button className="fixed bottom-0 left-10 text-sm z-50 rounded-t border-x border-t border-white/10 bg-black/80 shadow-lg backdrop-blur-lg group" onClick={scrollIntoView}>
          <Tooltip placement="right">
            <TooltipTrigger>
              <div className="flex text-left px-4 py-2">
                <section className="min-w-24">
                  <div className="text-gray-500 font-semibold whitespace-pre text-[10px]">
                    Next hard start
                  </div>
                  <span className="text-sm font-mono">
                    {nextHardStartStr}
                  </span>
                </section>
                <section className="min-w-24">
                  <div className="text-gray-500 font-semibold whitespace-pre text-[10px]">
                    Countdown
                  </div>
                  <span className={['text-sm font-mono', countdown < 10000 ? 'text-red-700' : ''].join(' ')}>
                    {countdown < 0 ? '+' : ''}{formatCountdown(countdown)}
                  </span>
                </section>
                <section className="min-w-32">
                  <div className="text-gray-500 font-semibold whitespace-pre text-[10px]">
                    {type || 'Status'}
                  </div>
                  <span className={['text-sm font-mono', textColor].join(' ')}>
                    {timeDiffStr}
                  </span>
                </section>
                <section className="mr-2">
                  <div className="text-gray-500 font-semibold whitespace-pre text-[10px]">
                    Title
                  </div>
                  <span className="text-sm">
                    {nextHardCue.title}
                  </span>
                </section>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              Scroll to cue
            </TooltipContent>
          </Tooltip>
        </button>
      </>
    )
  }

  return (
    <div className="leading-5 text-xs my-0.5 w-fit text-gray-500">
      <span className={textColor}>{icon && <FontAwesomeIcon icon={icon} />}</span>
      {' '}
      {text}
    </div>
  )
}

BetweenCuesIndicator.propTypes = {
  cue: PropTypes.object.isRequired,
  timezone: PropTypes.string,
}

export default memo(BetweenCuesIndicator)
