import ModalWrapper from './ModalWrapper.jsx'
import ShareableLinkAndAccessCode from './partials/ShareableLinkAndAccessCode.jsx'
import PropTypes from 'prop-types'
import { useAtom } from 'jotai'
import { useState, useEffect, useCallback } from 'react'
import { columnsAtom, rundownAtom } from '../../store/rundown.store.js'
import { generateRundownLinkWithSignature } from '../../utils/generateLinkWithSignature.js'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateRundown } from '../../firestore.js'
import * as OUTPUT_TYPES from '../../constants/outputConfigTypes.js'
import Button from '../Button.jsx'
import _without from 'lodash/without'

const OUTPUT_LAYOUTS = {
  [OUTPUT_TYPES.LIST]: (
    <>
      <div className="rounded-sm w-100 h-7 flex items-center justify-around gap-2">
        <span className="text-[8px] ml-1 font-mono">1</span>
        <span className="text-xs font-semibold  font-mono">00:00</span>
        <span className="text-[8px]">Title</span>
        <span className="grow"></span>
      </div>
      <div className="rounded-sm w-100 h-8 flex items-center justify-around gap-2">
        <span className="text-[8px] ml-1 font-mono">2</span>
        <span className="text-xs font-semibold  font-mono">00:00</span>
        <span className="text-[8px]">Title</span>
        <span className="grow"></span>
      </div>
    </>
  ),
  [OUTPUT_TYPES.SINGLE]: (
    <>
      <div className="rounded-sm w-100 h-2/3 text-sm font-semibold flex items-center justify-center font-mono">00:00</div>
      <div className="rounded-sm w-100 h-1/3 text-[10px] flex items-center justify-center">Current Cue title</div>
    </>
  ),
  [OUTPUT_TYPES.PROMPTER]: (
    <>
      <div className="rounded-sm w-100 pt-4 pl-2 h-1/3 text-[9px] flex items-center justify-center text-left">Scrollable text for your teleprompter</div>
    </>
  ),
}

export default function RundownOutputModal({ rundownId, open, setOpen }) {
  const [columns] = useAtom(columnsAtom)
  const [rundown] = useAtom(rundownAtom)
  const [options, setOptions] = useState({ columns: [] })
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState()
  const [configOptions, setConfigOptions] = useState('')
  const [configOptionsObject, setConfigOptionsObject] = useState({})
  const [edited, setEdited] = useState(false)

  function handleShowColumn (column) {
    setOptions({ ...options, column })
  }

  async function handleSaveAdvancedConfig (text) {
    return await updateRundown(rundownId, { 'settings.outputConfig': text })
  }

  const handleObjectifyConfig = () => {
    const options = configOptions?.split('\n')
    const configObject = {}

    options.forEach((option) => {
      const [key = '', value = ''] = option.split('=')
      if (!value) return null
      key.replace(/[^a-z0-9=,-]/gim, '')
      value.replace(/[^a-z0-9=,-]/gim, '')

      configObject[key] = value
    })
    setConfigOptionsObject(configObject)
  }

  /**
   * Objectify the config options, while sanitizing them to be url-friendly
   * and force-build a new URL with these options
   */
  const applyConfigOptions = useCallback(async () => {
    setEdited(false)
    handleObjectifyConfig()
    handleSaveAdvancedConfig(configOptions)
  }, [configOptions])

  useEffect(() => {
    if (rundown.settings?.outputConfig) setConfigOptions(rundown.settings.outputConfig)
  }, [rundown.settings])

  const handleSetSelectedLayout = (key) => {
    const newConfigObject = configOptionsObject

    newConfigObject[OUTPUT_TYPES.LAYOUT] = key

    setConfigOptionsObject(newConfigObject)
    setConfigOptions(Object.entries(newConfigObject).map(([key, value]) => `${key}=${value}`).join('\n'))
    buildUrl()
  }

  const handleResetConfigToDefault = () => {
    setEdited(true)
    setConfigOptions(Object.values(OUTPUT_TYPES.OutputConfigTypes).map(({ name, default: d }) => `${name}=${d}`).join('\n'))
  }

  function handleToggleColumn (event, id) {
    const updatedArray = event.target.checked ? [...options.columns, id] : _without(options.columns, id)
    setOptions({ ...options, columns: updatedArray })
  }

  /**
   * Build the shareable url from options and selectedLayout.
   * Is executed on onShow and every time the dependencies update.
   */
  const buildUrl = useCallback(async () => {
    setLoading(true)
    const newUrl = await generateRundownLinkWithSignature(rundownId, 'output', { ...options, ...configOptionsObject })
    setUrl(newUrl)
    setLoading(false)
  }, [rundownId, options, configOptionsObject, configOptions])

  useEffect(() => {
    buildUrl()
  }, [options, configOptionsObject])

  return (
    <ModalWrapper
      title="Generate Output"
      open={[open, setOpen]}
      onShow={() => {
        handleObjectifyConfig()
        handleShowColumn('')
      }}
    >
      <div className="grid grid-cols-2 gap-12">
        {/* LEFT SIDE */}
        <div>
          {/* Preview */}
          <p className="text-gray-400 font-light">Layout</p>
          {/* Cell editing */}
          <div className="space-y-2">
            {/* <p className="text-gray-400 font-light">Choose the layout</p> */}
            <div className="mt-2 flex w-full justify-between gap-4">
              {Object.entries(OUTPUT_LAYOUTS).map(([key, layout]) => {
                const isSelectedLayout = configOptionsObject[OUTPUT_TYPES.LAYOUT] || OUTPUT_TYPES.LIST
                return (
                  <button
                    key={key}
                    disabled={loading}
                    className={[
                      'w-1/2 aspect-video bg-gray-950 rounded border',
                      (key === isSelectedLayout ? 'border-white hover:border-gray-500' : 'border-transparent'),
                    ].join(' ')}
                    onClick={() => handleSetSelectedLayout(key)}
                  >
                    <div className="p-1 gap-1 flex flex-col h-full">
                      <div className="bg-gray-100/5 rounded-sm w-100 min-h-2"></div>
                      {layout}
                    </div>
                  </button>
                )
              })}
            </div>
          </div>
          {/* Column show and hide */}
          <div className="mt-4 space-y-2">
            {/* Column selection for list output */}
            {(!configOptionsObject[OUTPUT_TYPES.LAYOUT] || configOptionsObject[OUTPUT_TYPES.LAYOUT] === OUTPUT_TYPES.LIST)
            && (
              <>
                <p className="text-gray-400 font-light">Choose which columns to show</p>
                <div className={[
                  'flex flex-col gap-2 justify-center',
                ].join(' ')}
                >
                  {columns.map((column) => (
                    <label
                      key={column?.id}
                      htmlFor={`col-checkbox-${column?.id}`}
                      className={[
                        'p-2 bg-gray-800 rounded flex gap-2',
                      ].join(' ')}
                    >
                      <input
                        id={`col-checkbox-${column?.id}`}
                        type="checkbox"
                        className="focus:outline-none focus:ring disabled:pointer-events-none"
                        checked={options.columns?.includes(column?.id)}
                        onChange={(event) => handleToggleColumn(event, column?.id)}
                      />
                      <span>{column?.name || 'Unnamed Column'}</span>
                    </label>
                  ))}
                </div>
              </>
            )}
            {/* Column selection for prompter output */}
            {configOptionsObject[OUTPUT_TYPES.LAYOUT] === OUTPUT_TYPES.PROMPTER
            && (
              <>
                <p className="text-gray-400 font-light">
                  Select column
                </p>
                <select
                  onChange={(e) => handleShowColumn(e.target.value)}
                  value={options.column}
                  className={[
                    'p-2 w-full bg-gray-800 rounded flex gap-2 cursor-pointer border hover:brightness-110 disabled:brightness-100 disabled:opacity-60 disabled:pointer-events-none focus:outline-none focus:ring',
                    !options.column ? 'border-red-500' : 'border-transparent', // Visual reminder that a prompter column should be added
                  ].join(' ')}
                >
                  <option value="">None</option>
                  {columns.map((col) => (
                    <option key={col.id} value={col.id}>
                      {col.name || 'Unnamed Column'}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>
        </div>

        {/* RIGHT SIDE */}
        <div className="flex flex-col gap-4">

          {/* Advanced Configuration */}
          <div className="">
            <p className="text-gray-400 font-light">
              Advanced configuration
              {' '}
              {' '}
              <a
                href="https://rundownstudio.app/docs/sharing/output/#advanced-output-config-options"
                className="underline whitespace-nowrap text-sm focus:outline-none focus:ring"
                target="_blank"
                rel="noreferrer"
              >
                See available options
                {' '}
                <FontAwesomeIcon icon={faExternalLink} />
              </a>
            </p>
            <textarea
              className="mt-2 p-2 bg-gray-800 w-full text-xs font-mono rounded focus:outline-none focus:ring"
              value={configOptions}

              placeholder={`logo=on\ntime-of_day=on\n...`}
              onChange={(e) => {
                setEdited(true)
                setConfigOptions(e.target.value)
              }}
              rows={16}
            />
            <div className="flex justify-between">
              <button
                className="text-xs text-gray-400 focus:outline-none focus:ring disabled:opacity-50"
                onClick={handleResetConfigToDefault}
              >
                Reset to default
              </button>
              <Button
                text="Apply changes"
                size="small"
                className="px-2 py-1 text-xs font-base"
                onClick={applyConfigOptions}
                disabled={!edited}
                colour={edited ? 'main' : 'dark'}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 space-y-2">
        <p className="text-gray-400 font-light">Shareable Link</p>
        <ShareableLinkAndAccessCode
          className=""
          url={url}
          loading={loading}
        />
      </div>
    </ModalWrapper>
  )
}

RundownOutputModal.propTypes = {
  rundownId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}
