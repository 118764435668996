import PropTypes from 'prop-types'
import { formatCountdown } from '../../utils/formatTime.js'
import ContentEditable from '../ContentEditable.jsx'
import { memo } from 'react'

function CueItemHeader ({
  cue,
  onUpdateCue,
  readonly = false,
}) {
  return (
    <div className="h-full flex">
      <div className="px-3 py-2 flex flex-col justify-center w-full">
        <div className="flex-1">
          {/* Spacer */}
        </div>
        <div className="flex-0 leading-6">
          <ContentEditable
            className="w-full"
            value={cue.title}
            placeholder={readonly ? '' : 'Title'}
            onChange={(title) => onUpdateCue({ title })}
            disabled={readonly || cue.locked}
          />
        </div>
        <div className="flex-1">
          <CueItemSubtitle
            className="text-sm text-white/60"
            text={cue.subtitle}
            updateText={(subtitle) => onUpdateCue({ subtitle })}
            disabled={readonly || cue.locked}
            readonly={readonly}
          />
        </div>
      </div>
    </div>
  )
}

CueItemHeader.propTypes = {
  cue: PropTypes.object.isRequired,
  onUpdateCue: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
}

export default memo(CueItemHeader, (prevProps, nextProps) => {
  // Compare only the properties that affect rendering
  return (
    prevProps.cue.title === nextProps.cue.title
    && prevProps.cue.subtitle === nextProps.cue.subtitle
    && prevProps.cue.locked === nextProps.cue.locked
    && prevProps.readonly === nextProps.readonly
  )
})

export function CueItemElapsed ({ className, overtimeClassName, moment }) {
  const overtime = moment.left <= 0
  return (
    <div
      className={[
        'px-2',
        (overtime ? 'bg-gray-900 rounded text-red-500' : 'text-white'),
        (overtime && overtimeClassName),
        className,
      ].join(' ')}
    >
      {overtime && '+'}
      {formatCountdown(moment.countdown)}
    </div>
  )
}

CueItemElapsed.propTypes = {
  className: PropTypes.string,
  overtimeClassName: PropTypes.string,
  moment: PropTypes.object.isRequired,
}

function CueItemSubtitle ({
  className,
  text = '',
  updateText,
  disabled = false,
  readonly = false,
}) {
  return (
    <div className={[className, 'text-left'].join(' ')}>
      <ContentEditable
        value={text}
        placeholder={readonly ? '' : 'Subtitle'}
        onChange={updateText}
        disabled={disabled}
      />
    </div>
  )
}

CueItemSubtitle.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  updateText: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
}
