import { formatTimeOfDay as formatTimeOfDayUtil } from '@rundown-studio/timeutils'

/**
 * formatTimeOfDay - Wrapper function to pass in browser defaults to our util function
 * @param date — The date object to format
 * @param options.timezone — The IANA timezone name, e.g., 'America/New_York'
 * @param options.format — The time format, either '12hNoAmPm', '12h' or '24h'
 * @param options.seconds — When to display seconds: 'always', 'nonzero', or 'never'
 * @returns - The formatted time string
 */
export default function formatTimeOfDay (date, options = {}) {
  const _options = options

  // Check if browser is set for 12h or 24h
  const formattedTime = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).format(0)
  const is12h = /AM|PM/i.test(formattedTime)

  // Check if options.format is an empty string, default to the browser default
  if (!options.format || options.format === '') _options.format = is12h ? '12h' : '24h'

  return formatTimeOfDayUtil(date, _options)
}
