import { useState, useEffect, useCallback } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAtom } from 'jotai'
import Layout from '../../components/Layout.jsx'
import Button from '../../components/Button.jsx'
import { getEventsByTeam, getRundownsByTeam, getTeam, getTeamPlan } from '../../firestore.js'
import { planAtom } from '../../store/plan.store.js'
import { userAtom, userTeamAtom, userTeamsAtom } from '../../store/user.store.js'
import { rundownListAtom } from '../../store/rundownList.store.js'
import { getUser, getUserTeams } from '../../firebase.js'
import { eventListAtom } from '../../store/eventList.store.js'

export default function DashboardParent () {
  const { teamId } = useParams()
  const [initialLoadDone, setInitialLoadDone] = useState(false)
  const [error, setError] = useState(null)
  const [user, setUser] = useAtom(userAtom)
  const [team, setTeam] = useAtom(userTeamAtom)
  const [teams, setTeams] = useAtom(userTeamsAtom)
  const [rundownList, setRundownList] = useAtom(rundownListAtom)
  const [eventList, setEventList] = useAtom(eventListAtom)
  const [plan, setPlan] = useAtom(planAtom)

  //
  // Subscribe to Firestore updates on all relevant documents
  // Only resolve when initial payloads are loaded
  //
  const requestData = useCallback(() => {
    const promises = []

    // Define async functions for each task
    const fetchUserData = async () => {
      const userData = await getUser()
      setUser(userData)
    }

    const fetchUserTeam = async () => {
      const { data: teamData } = await getTeam(teamId)
      setTeam(teamData)
    }

    const fetchUserTeams = async () => {
      const teamsData = await getUserTeams()
      setTeams(teamsData)
    }

    const fetchUserPlan = async () => {
      const { data: planData } = await getTeamPlan(teamId)
      setPlan(planData)
    }

    const fetchTeamRundowns = async () => {
      const { data } = await getRundownsByTeam(teamId)
      setRundownList(data)
    }

    const fetchTeamEvents = async () => {
      const { data } = await getEventsByTeam(teamId)
      setEventList(data)
    }

    // Push the async functions to promises array
    promises.push(fetchUserData())
    promises.push(fetchUserTeam())
    promises.push(fetchUserTeams())
    promises.push(fetchUserPlan())
    promises.push(fetchTeamRundowns())
    promises.push(fetchTeamEvents())

    // Await all promises
    return Promise.all(promises)
  }, [teamId])

  //
  // perform the initial load
  //
  useEffect(() => {
    initialLoad()
  }, [teamId])
  async function initialLoad () {
    setInitialLoadDone(false)
    try {
      await requestData()
    } catch (err) {
      console.error(err)
      setError(err)
    }
    setInitialLoadDone(true)
  }

  if (initialLoadDone) return (
    <Outlet
      context={{
        user,
        team,
        teams,
        plan,
        rundownList,
        eventList,
      }}
    />
  )
  else if (error) return <DashboardError error={error} />
  else return <DashboardLoader />
}

function DashboardLoader() {
  return <Layout title="Loading..." loading={true} />
}

function DashboardError({ error = new Error('Not found') }) {
  const navigate = useNavigate()
  return (
    <Layout title="Error">
      {error?.response?.data || error?.message}
      <Button text="Try logging in" onClick={() => navigate(`/login?next=${window.location.pathname}`)} />
    </Layout>
  )
}

DashboardError.propTypes = {
  error: PropTypes.instanceOf(Error),
}
