import { faAdd, faBolt, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import ColumnHeader from './ColumnHeader.jsx'
import _indexOf from 'lodash/indexOf'
import { addRundownColumn, reorderRundownColumns, updateRundownColumn } from '../../../firestore.js'
import { useAtom } from 'jotai'
import { getColumnById } from '../../../store/rundown.store.js'
import _without from 'lodash/without'
import { RundownToken } from '../../../axios.js'
import { ACCESS_WRITE } from '../../../constants/rundownAccessStates.js'
import Button from '../../Button.jsx'
import UpgradeModal from '../../modal/UpgradeModal.jsx'
import PropTypes from 'prop-types'
import { Menu, MenuItem, MenuSeparator } from '../../interactives/DropdownMenu.jsx'

export default function ColumnHeaders ({ columns = [], hiddenColumns = [], columnsOrder = [], plan }) {
  const { rundownId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [columnById] = useAtom(getColumnById)
  const [loading, setLoading] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)

  const updateSearchParams = async (columnId) => {
    const hiddenColsArray = searchParams.get('hiddenColumns').split(',')
    switch (columnId) {
      case 'show_all':
        searchParams.delete('hiddenColumns')
        return setSearchParams(searchParams)

      default:
        searchParams.set('hiddenColumns', _without(hiddenColsArray, columnId).join(','))
        return setSearchParams(searchParams)
    }
  }

  const columnLimitExceeded = plan.limits.columns !== -1 && plan.limits.columns <= columns.length
  const handleAddNewColumn = async () => {
    if (columnLimitExceeded) return setShowUpgradeModal(true)

    setLoading(true)
    await addRundownColumn(rundownId)
    return setLoading(false)
  }

  const handleReorderColumns = (columnId, direction) => {
    const columnsArray = columnsOrder.slice()
    const indexFrom = _indexOf(columnsArray, columnId)
    const indexTo = direction === 'right' ? indexFrom + 1 : indexFrom - 1
    columnsArray.splice(indexFrom, 1)
    columnsArray.splice(indexTo, 0, columnId)
    return reorderRundownColumns(rundownId, columnsArray)
  }

  const handleUpdateWidth = (columnId, width) => {
    return updateRundownColumn(rundownId, columnId, { width })
  }

  return (

    <div className="flex gap-1">
      {columns.map((column) => {
        return (
          <ColumnHeader
            key={`${column?.id}_${_indexOf(columnsOrder, column?.id)}`}
            column={column}
            hiddenColumns={hiddenColumns}
            moveColumn={handleReorderColumns}
            updateWidth={handleUpdateWidth}
          />
        )
      })}
      {RundownToken.access === ACCESS_WRITE
      && (
        <Button
          icon={faAdd}
          colour="dark"
          text="Add column"
          size="sm"
          className="px-2"
          onClick={handleAddNewColumn}
          loading={loading}
          toolTip={columnLimitExceeded
            ? (
                <>
                  Limit reached
                  <FontAwesomeIcon icon={faBolt} className="text-yellow-500" />
                  {' '}
                  Upgrade for more
                </>
              )
            : ''}
        />
      )}

      <UpgradeModal
        open={showUpgradeModal}
        setOpen={setShowUpgradeModal}
        onHide={() => setShowUpgradeModal(false)}
        message="You&apos;ve reached your limit for columns on this rundown."
      />

      {(hiddenColumns.length > 0 && RundownToken.access === ACCESS_WRITE) && (
        <div className="relative px-2">
          <Menu icon={faEye} className="!h-7">
            <MenuItem disabled={true} label="Show column" />
            <MenuSeparator />
            {
              hiddenColumns.map((c) => {
                return <MenuItem key={c} label={columnById(c)?.name} onClick={updateSearchParams.bind(null, c)} />
              })
            }
            <MenuSeparator />
            <MenuItem label="Show all" onClick={updateSearchParams.bind(null, 'show_all')} />
          </Menu>
        </div>
      )}
    </div>
  )
}

ColumnHeaders.propTypes = {
  columns: PropTypes.array,
  hiddenColumns: PropTypes.array,
  columnsOrder: PropTypes.array,
  plan: PropTypes.object,
}
