import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faArrowsLeftRight, faEllipsis, faEyeSlash, faPlus, faTableColumns, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import EditableInput from '../../EditableInput.jsx'
import { addRundownColumn, deleteRundownColumn, updateRundownColumn } from '../../../firestore.js'
import { useParams, useSearchParams } from 'react-router-dom'
import { ACCESS_WRITE, ACCESS_WRITE_COLUMN } from '../../../constants/rundownAccessStates.js'
import { RundownToken } from '../../../axios.js'
import _without from 'lodash/without'
import _clamp from 'lodash/clamp'
import { useSetAtom } from 'jotai'
import { setColumnWidthAtom } from '../../../store/rundown.store'
import { DEFAULT_COLUMN_WIDTH } from '../../../constants/columnWidth.js'
import PropTypes from 'prop-types'
import { Menu, MenuItem, MenuSeparator } from '../../interactives/DropdownMenu.jsx'

export default function ColumnHeader ({ column = {}, hiddenColumns = [], moveColumn, updateWidth }) {
  const { rundownId } = useParams()
  const setColumnWidth = useSetAtom(setColumnWidthAtom)
  const [touched, setTouched] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const hidden = hiddenColumns.includes(column.id)
  const canEdit = RundownToken.access === ACCESS_WRITE

  const editableColumns = searchParams?.get('edit-columns')?.split(',') || []

  const updateSearchParams = () => {
    // const searchParams = new URLSearchParams(window.location.search)
    let colIds = (searchParams.get('hiddenColumns') || '').split(',').filter(Boolean)
    if (colIds.includes(column.id)) colIds = _without(colIds, column.id)
    else colIds.push(column.id)
    searchParams.set('hiddenColumns', colIds)
    setSearchParams(searchParams)
  }

  const [dividerDragging, setDividerDragging] = useState(false)
  const [dividerPos, setDividerPos] = useState(column.width || DEFAULT_COLUMN_WIDTH)

  const containerElement = useRef()
  const memoizedOnDividerMove = useCallback(onDividerMove, [dividerDragging])
  const memoizedOnDividerUp = useCallback(onDividerUp, [dividerDragging])

  function onDividerDown (event) {
    event.preventDefault()
    setDividerDragging(true)
  }

  function onDividerMove (event) {
    event.preventDefault()
    if (!dividerDragging) return
    setTouched(true)
    const bounding = containerElement?.current.getBoundingClientRect()
    const newPos = (event.clientX) - bounding.left
    const MIN_POS = 100 // never smaller than 100px
    const MAX_POS = 800 // never bigger than 800px
    setDividerPos(_clamp(newPos, MIN_POS, MAX_POS))
    // setColumnWidth(column.id, _clamp(newPos, MIN_POS, MAX_POS))
  }

  function onDividerUp (event) {
    event.preventDefault()
    setDividerDragging(false)
    setTouched(false)
    setColumnWidth(column.id, containerElement?.current.getBoundingClientRect().width)
    updateWidth(column.id, containerElement?.current.getBoundingClientRect().width)
  }

  useEffect(() => {
    if (dividerDragging) {
      document.addEventListener('mousemove', memoizedOnDividerMove)
      document.addEventListener('mouseup', memoizedOnDividerUp)
    }
    return () => {
      document.removeEventListener('mousemove', memoizedOnDividerMove)
      document.removeEventListener('mouseup', memoizedOnDividerUp)
    }
  }, [dividerDragging])

  useEffect(() => {
    if (!touched) setDividerPos(column.width)
  }, [column.width])

  if (hidden) return null
  return (
    <div
      ref={containerElement}
      style={{
        width: `${dividerPos}px`,
      }}
      className="group relative"
    >
      <div className="relative flex gap-2 border-b-2 border-white/20">
        <EditableInput
          className="flex-1 h-6 text-sm w-[8rem] text-white truncate overflow-ellipsis"
          value={column.name}
          placeholder="Column name"
          onChange={(name) => updateRundownColumn(rundownId, column.id, { name })}
          disabled={!canEdit}
        />
        {canEdit ? (
          <Menu
            icon={faEllipsis}
            className="mr-6 !h-6 !w-6 !p-1 opacity-0 group-hover:opacity-30 hover:!opacity-100 transition-opacity"
          >
            <MenuItem
              label="Hide column for me"
              onClick={updateSearchParams.bind(null, true)}
              icon={faEyeSlash}
            />
            <Menu label="Column type..." icon={faTableColumns}>
              <MenuItem
                label="Richtext"
                onClick={updateRundownColumn.bind(null, rundownId, column.id, { type: 'richtext' })}
                active={column.type === 'richtext'}
              />
              <MenuItem
                label="Dropdown"
                onClick={updateRundownColumn.bind(null, rundownId, column.id, { type: 'select' })}
                active={column.type === 'select'}
              />
            </Menu>
            <MenuItem
              label="Add new column"
              onClick={addRundownColumn.bind(null, rundownId, { after: column.id })}
              icon={faPlus}
            />
            <MenuItem
              label="Move left"
              onClick={moveColumn.bind(null, column.id, 'left')}
              icon={faArrowLeft}
            />
            <MenuItem
              label="Move right"
              onClick={moveColumn.bind(null, column.id, 'right')}
              icon={faArrowRight}
            />
            <MenuSeparator />
            <MenuItem
              label="Delete column"
              onClick={deleteRundownColumn.bind(null, rundownId, column.id)}
              icon={faTrashAlt}
            />
          </Menu>
        ) : ''}
        {RundownToken.access === ACCESS_WRITE_COLUMN && editableColumns?.includes(column?.id)
          ? (
              <div className="px-1 py-0.5 bg-green-900 text-green-100 rounded-sm text-xs my-auto">Editable</div>
            )
          : ''}
        {/* RESIZER */}
        {RundownToken.access === ACCESS_WRITE
        && (
          <div
            onMouseDown={onDividerDown}
            className={['absolute right-1 flex-none h-6 w-4 opacity-0 group-hover:opacity-30 hover:!opacity-100 px-1 cursor-col-resize transition-opacity'].join(
              ' ',
            )}
          >
            <FontAwesomeIcon icon={faArrowsLeftRight} />
          </div>
        )}
      </div>
      {dividerDragging && <span className="absolute right-0 bg-gray-500 w-0.5 h-screen inline-block"></span>}
    </div>
  )
}

ColumnHeader.propTypes = {
  column: PropTypes.object,
  hiddenColumns: PropTypes.array,
  moveColumn: PropTypes.func,
  updateWidth: PropTypes.func,
}
