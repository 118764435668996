import PropTypes from 'prop-types'
import ContentEditable from '../ContentEditable.jsx'
import { CUE_TYPE_CUE, CUE_TYPE_HEADING, CUE_TYPE_GROUP } from '../../constants/cueTypes.js'
import { memo } from 'react'

const editableTitleDefaults = {
  [CUE_TYPE_CUE]: 'Title',
  [CUE_TYPE_HEADING]: 'Heading',
  [CUE_TYPE_GROUP]: 'Group name',
}

function CueItemTitle ({
  className,
  cue,
  onUpdateCue,
  readonly = false,
  collapsed,
  childrenCount = 0,
}) {
  return (
    <div className="h-full w-full flex flex-col items-start justify-center px-4">
      <ContentEditable
        className={className}
        value={cue.title}
        placeholder={editableTitleDefaults[cue.type]}
        onChange={(title) => onUpdateCue({ title })}
        disabled={readonly}
      />
      {(collapsed && childrenCount > 0) && (
        <div className="text-sm opacity-50">
          {childrenCount}
          {' '}
          hidden
        </div>
      )}
    </div>
  )
}

CueItemTitle.propTypes = {
  className: PropTypes.string,
  cue: PropTypes.object.isRequired,
  onUpdateCue: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  collapsed: PropTypes.bool,
  childrenCount: PropTypes.number,
}

export default memo(CueItemTitle, (prevProps, nextProps) => {
  return (
    prevProps.className === nextProps.className
    && prevProps.cue.title === nextProps.cue.title
    && prevProps.cue.type === nextProps.cue.type
    && prevProps.readonly === nextProps.readonly
    && prevProps.collapsed === nextProps.collapsed
    && prevProps.childrenCount === nextProps.childrenCount
  )
})
