import CurrentTimeOfDay from '../CurrentTimeOfDay.jsx'
import PropTypes from 'prop-types'
import formatTimezone from '../../../utils/formatTimezone.js'
import OverUnder from '../timing/OverUnder.jsx'

export default function TimeAndOverUnder ({
  timezone,
  todDisplayFormat,
}) {
  return (
    <div className={['flex gap-6'].join(' ')}>
      <section>
        <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
          Time of day
          {' '}
          { timezone ? '(' + formatTimezone(timezone, 'abbr') + ')' : '' }
        </div>
        <span className={['font-mono text-sm'].join(' ')}>
          <CurrentTimeOfDay
            timezone={timezone}
            todDisplayFormat={todDisplayFormat}
          />
        </span>
      </section>
      <section>
        <OverUnder />
      </section>
    </div>
  )
}

TimeAndOverUnder.propTypes = {
  timezone: PropTypes.string,
  todDisplayFormat: PropTypes.string,
}
