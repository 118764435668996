import { useParams } from 'react-router-dom'
import { Fragment, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { faBolt, faChevronDown, faExclamationCircle, faHeading, faLayerGroup, faPlus } from '@fortawesome/free-solid-svg-icons'
import { addRundownCue, groupRundownCues, reorderRundownCues, updateRundownCue } from '../../firestore.js'
import Button from '../Button.jsx'
import CueItem from './CueItem.jsx'
import TimeInput from './TimeInput.jsx'
import KeyboardNavigationHandler from './KeyboardNavigationHandler.jsx'
import { formatDurationHuman, floorSeconds } from '../../utils/formatTime.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _indexOf from 'lodash/indexOf'
import _groupBy from 'lodash/groupBy'
import _debounce from 'lodash/debounce'
import _find from 'lodash/find'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { setCueOrderAtom, setCuesAtom, loadedCuesAtom, maxLoadedCuesAtom, collapsedGroupsAtom, firstCueIdAtom, setCueAtom } from '../../store/rundown.store.js'
import { RundownToken } from '../../axios.js'
import { ACCESS_WRITE } from '../../constants/rundownAccessStates.js'
import UpgradeModal from '../modal/UpgradeModal.jsx'
import UpgradeMessage from '../UpgradeMessage.jsx'
import { CUE_TYPE_CUE, CUE_TYPE_GROUP, CUE_TYPE_HEADING } from '../../constants/cueTypes.js'
import { CUE_BACKGROUND_COLORS } from '@rundown-studio/consts'
import RundownColoursModal from '../modal/RundownColoursModal.jsx'
import Bowser from 'bowser'
import { Menu, MenuItem } from '../interactives/DropdownMenu.jsx'
import { CueStartMode } from '@rundown-studio/types'
import RundownDayTZ from './timing/RundownDayTZ.jsx'
import OverUnder from './timing/OverUnder.jsx'
import EndOfShow from './timing/EndOfShow.jsx'
import BatchEditToolbar from './BatchEditToolbar.jsx'
import PlannedEnd from './timing/PlannedEnd.jsx'

/**
 * Mobile devices, specifically webkit, struggle with large rundowns more than desktop devices.
 * So we lower the amount of TipTap editors loaded.
 */
const browser = Bowser.getParser(window.navigator.userAgent)
const isLowPerformance = browser.getEngineName() === 'WebKit' || browser.getPlatformType() !== 'desktop'
const MAX_LOADED_CELLS = isLowPerformance ? 50 : 300

function _insertItemAtIndex(array, item, index) {
  if (index === -1) {
    // Index is -1, meaning the item should be added at the end
    array.push(item)
  } else {
    // Insert the item at the specified index
    array.splice(index, 0, item)
  }
}

export default function RundownBody ({
  rundown,
  cues,
  columns,
  cells,
  runner,
  hiddenColumns = [],
  readonly = false,
  plan,
}) {
  const { rundownId } = useParams()
  const [loading, setLoading] = useState(false)
  const [draggedCue, setDraggedCue] = useState(null)
  const [draggedParentId, setDraggedParentId] = useState(null)
  const [draggedOverParentId, setDraggedOverParentId] = useState(null)
  const [draggedOverCue, setDraggedOverCue] = useState(null)
  const [draggingGroup, setDraggingGroup] = useState(false)
  const [cueCountToAdd, setCueCountToAdd] = useState(1)
  const clearDraggedOverCue = _debounce(() => setDraggedOverCue(null), 150)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const setCueOrder = useSetAtom(setCueOrderAtom)
  const setCues = useSetAtom(setCuesAtom)
  const [collapsedGroups, setCollapsedGroups] = useAtom(collapsedGroupsAtom)
  const [autoAddGroup, setAutoAddGroup] = useState(null)
  const [rundownColoursModalOpen, setRundownColoursModalOpen] = useState(false)
  const setMaxLoadedCues = useSetAtom(maxLoadedCuesAtom)
  const [loadedCues, setLoadedCues] = useAtom(loadedCuesAtom)
  const firstCueId = useAtomValue(firstCueIdAtom)
  const setCue = useSetAtom(setCueAtom)

  const cuesLimitExceeded = plan.limits.cues !== -1 && plan.limits.cues < (cues.length + cueCountToAdd)
  const cuesTypesMap = Object.fromEntries(Object.entries(cues).map(([id, cue]) => [id, cue.type]))

  const cueTypesTitleMap = {
    [CUE_TYPE_CUE]: {
      title: 'New cue',
      colour: '',
    },
    [CUE_TYPE_HEADING]: {
      title: 'New heading',
      backgroundColor: 'transparent',
    },
    [CUE_TYPE_GROUP]: {
      title: 'New group',
      backgroundColor: CUE_BACKGROUND_COLORS[6],
    },
  }

  async function handleAddCue (type = CUE_TYPE_CUE) {
    // Check user limits
    if (cuesLimitExceeded) return setShowUpgradeModal(true)

    setLoading(true)
    const { data: [rundown, newCues] } = await addRundownCue(rundownId, { count: cueCountToAdd }, { type, title: cueTypesTitleMap[type].title, backgroundColor: cueTypesTitleMap[type].backgroundColor }).finally(() => setLoading(false))
    setCueCountToAdd(1)
    setCueOrder(rundown.cues)
    setCues(newCues)

    return newCues
  }

  /**
   * [reorderCues description]
   * @param  {string} cueId      [cueId of the item being moved]
   * @param  {string} targetCueId [cueId of the current item at the new position, insert before]
   * @return {void}
   */
  async function reorderCues (cueId, targetCueId) {
    if (cueId === targetCueId) return

    setLoading(true)

    // Moved cue is fixed and is going to be first cue, set as flexible
    if (targetCueId === firstCueId && cues[cueId].startMode === CueStartMode.FIXED) {
      const { data } = await updateRundownCue(rundownId, cueId, { startMode: CueStartMode.FLEXIBLE })
      setCue(data)
    }

    const targetCue = _find(rundown.cues, { id: targetCueId })

    // If dropping on another cue, create a group with the two new cues
    // And avoid creating a new group if dropping over a group
    if (autoAddGroup === targetCueId && !targetCue.children) {
      // Remove [cueId] and [targetCueId] from the UI
      rundown.cues.splice(_indexOf(rundown.cues, { id: targetCueId }), 1)
      rundown.cues.splice(_indexOf(rundown.cues, { id: cueId }), 1)

      await groupRundownCues(rundownId, targetCueId, [targetCueId, cueId])
      return setLoading(false)
    }

    let previousCuesOrder = rundown.cues
    let removedItem
    let parent = draggedParentId ? _find(previousCuesOrder, { id: draggedParentId }) : null
    let parentTo = _find(previousCuesOrder, { id: draggedOverParentId })

    if (draggedParentId) {
      // Remove cue from within the group
      const child = _find(parent.children, { id: cueId })
      const childIndex = _indexOf(parent.children, child)
      removedItem = parent.children.splice(childIndex, 1)

      let indexTo = targetCueId === 'end' ? previousCuesOrder.length : _indexOf(parentTo?.children, _find(parentTo?.children, { id: targetCueId }))

      if (targetCueId === 'end' || !draggedOverParentId) {
        // Cue is being placed at the end of the main list
        indexTo = _indexOf(previousCuesOrder, targetCue)
        _insertItemAtIndex(previousCuesOrder, removedItem[0], indexTo)
      } else {
        // Cue is being added within a list
        _insertItemAtIndex(parentTo.children, removedItem[0], indexTo)
      }
    } else {
      // Remove cue from the main cue list
      const cueFromObject = previousCuesOrder.find((cue) => cue.id === cueId)
      const indexFrom = _indexOf(previousCuesOrder, cueFromObject)
      removedItem = previousCuesOrder.splice(indexFrom, 1)

      const cueToObject = previousCuesOrder.find((cue) => cue.id === targetCueId)
      let indexTo = targetCueId === 'end' ? previousCuesOrder.length : _indexOf(previousCuesOrder, cueToObject)

      if (indexTo === -1) {
        // Cue is not part of a group but will be
        parent = _find(previousCuesOrder, { id: draggedOverParentId })
        const targetChild = _find(parent?.children, { id: targetCueId })
        const targetChildIndex = _indexOf(parent?.children, targetChild)
        _insertItemAtIndex(parent?.children, removedItem[0], targetChildIndex)
      } else {
        // Cue is not part of a group and will remain not part of a group
        _insertItemAtIndex(previousCuesOrder, removedItem[0], indexTo)
      }
    }

    await reorderRundownCues(rundownId, previousCuesOrder)
    return setLoading(false)
  }

  function handleItemCollapse (cueId, force = false) {
    if (!collapsedGroups.includes(cueId) || force) {
      const newCollapsedGroups = [...collapsedGroups, cueId]
      setCollapsedGroups(newCollapsedGroups)
    } else {
      const newCollapsedGroups = collapsedGroups.filter((id) => id !== cueId)
      setCollapsedGroups(newCollapsedGroups)
    }
  }

  function onDragStart (event, id, parentId) {
    setDraggedCue(id)
    setDraggedOverCue(id)
    setDraggedParentId(parentId)
    const isTypeGroup = _find(cues, { id: id }).type === CUE_TYPE_GROUP
    if (isTypeGroup) {
      handleItemCollapse(id, true)
      setDraggingGroup(true)
    }
  }

  function onDrop (event, id) {
    reorderCues(draggedCue, id)
    setDraggedCue(null)
    setDraggedOverCue(null)
    setDraggedParentId(null)
    setDraggingGroup(false)
    setAutoAddGroup(null)

    // Expand group if adding a cue to it
    if (draggedOverParentId) {
      setCollapsedGroups([...collapsedGroups.filter((id) => id !== draggedOverParentId)])
    }
  }

  function onDragOver (event, id, parentId = null) {
    if (!draggedCue) return
    if (draggingGroup && parentId) return
    clearDraggedOverCue.cancel()
    setDraggedOverCue(id)
    setDraggedOverParentId(parentId)
    event.preventDefault() // cancels MacOS drag animation and enabled 'onDrop'

    // Skip below code if dragging a group
    if (_find(cues, { id: draggedCue }).type === CUE_TYPE_GROUP) return

    // Check if hovering over the upper or lower half
    const targetElement = event.currentTarget
    const elementHeight = targetElement.offsetHeight
    const mouseY = event.clientY
    const elementMidpoint = targetElement.getBoundingClientRect().top + elementHeight / 2
    // Checking position and checking that the hovered/dragged cue is not already part of a group
    if (mouseY > elementMidpoint && !draggedOverParentId && !draggedParentId) {
      setAutoAddGroup(id)
    } else {
      setAutoAddGroup(null)
    }
  }

  function onDragLeave () {
    if (!draggedCue) return
    setDraggedOverCue(null)
    setDraggedOverParentId(null)
    clearDraggedOverCue()
  }

  const cellMap = useMemo(() => _groupBy(cells, (cell) => cell.cueId), [cells])

  /**
   * Expand a collapsed group if it contains the "next" cue
   */
  useEffect(() => {
    if (!runner) return
    if (runner.timesnap.running && runner.nextCueId) {
      // const nextCuePartentId = _find(cues, {id: runner.nextCueId})
      const filteredByCollapsedGroups = rundown.cues.filter((cue) =>
        cue.children?.find((child) => child.id === runner.nextCueId),
      )
      if (filteredByCollapsedGroups.length && collapsedGroups.includes(filteredByCollapsedGroups[0]?.id)) handleItemCollapse(filteredByCollapsedGroups[0]?.id)
    }
  }, [runner])

  /**
   * Determine how many cues can stay loaded and add the first x cues if empty.
   */
  useEffect(() => {
    const maxLoadedCues = Math.ceil(MAX_LOADED_CELLS / columns.length)
    setMaxLoadedCues(maxLoadedCues)
    const initialCuesToLoad = rundown.cues.map((cue) => cue.id).slice(0, maxLoadedCues)
    if (loadedCues.length === 0) setLoadedCues(initialCuesToLoad)
  }, [columns])

  /**
   * Create a CueItem component
   * @param  {string} options.index
   * @param  {string} options.cueId - ID of this cue
   * @param  {string} [options.parentId] - ID of parent cue if this is a child
   * @param  {object[]} [options.children] - List of children if this is a parent
   * @param  {boolean} [options.lastSibling]
   * @return {ReactElement}
   */
  function createCueItem ({
    index,
    cueId,
    parentId = undefined,
    children = undefined,
    lastSibling = false,
  }) {
    return cues[cueId]
      ? (
          <CueItem
            key={cueId}
            rundownId={rundownId}
            runner={runner}
            timezone={rundown.timezone}
            cue={cues[cueId]}
            index={index}
            cells={cellMap[cueId]}
            columns={columns}
            isNext={cueId === runner?.nextCueId}
            hiddenColumns={hiddenColumns}
            readonly={readonly}
            onDragStart={(e) => onDragStart(e, cueId, parentId)}
            onDragOver={(e) => onDragOver(e, cueId, parentId)}
            onDrop={(e) => onDrop(e, cueId)}
            dragging={draggedCue === cueId}
            dragover={draggedOverCue === cueId && draggedCue !== cueId}
            setRundownColoursModalOpen={setRundownColoursModalOpen}
            cueBackgroundColours={rundown.settings?.cueBackgroundColours || CUE_BACKGROUND_COLORS}
            handleItemCollapse={handleItemCollapse}
            collapsedGroups={collapsedGroups}
            childrenCues={children}
            cuesTypesMap={cuesTypesMap}
            parentBackgroundColour={parentId ? cues[parentId]?.backgroundColor : cues[cueId]?.backgroundColor}
            running={runner?.timesnap?.running}
            parentId={parentId}
            lastSibling={lastSibling}
            draggingGroup={parentId ? draggingGroup : false}
            autoAddGroup={!parentId && !children ? autoAddGroup : undefined}
            currentCueHighlightColor={rundown.settings?.currentCueHighlightColor}
          />
        )
      : null
  }

  let displayIndex = 0

  return (
    <div
      className={['flex flex-col gap-0', runner?.timesnap?.running ? 'pb-[70vh]' : 'pb-20'].join(' ')}
      onDragLeave={onDragLeave}
    >

      <div className="mx-8 my-2 relative">
        <BatchEditToolbar
          rundownId={rundownId}
          cues={cues}
          cueOrder={rundown.cues}
          setRundownColoursModalOpen={setRundownColoursModalOpen}
        />
        <RundownDayTZ timezone={rundown.timezone} readonly={readonly} />
      </div>

      {
        /*
         * Cue List
         * Note: We use the `rundown.cues` list of IDs to get the correct order of parents and children
         */
        rundown?.cues.map((cue) => {
          // Free plan limit check
          if (cuesLimitExceeded && displayIndex >= plan.limits.cues) return null

          if (cues[cue.id]?.type !== CUE_TYPE_HEADING) {
            displayIndex++
          }

          if (cue?.children) {
            let displayChildIndex = 0

            return (
              <Fragment key={cue.id}>
                {/* Parent Cue */}
                {createCueItem({
                  index: `${displayIndex}`,
                  cueId: cue.id,
                  parentId: undefined,
                  children: cue.children.map((c) => ({ ...c, type: cues[c.id]?.type })),
                  lastSibling: false,
                })}
                {collapsedGroups.includes(cue.id)
                  ? null
                  : cue.children.map((child, childIndex) => {
                    if (cues[child.id]?.type !== CUE_TYPE_HEADING) {
                      displayChildIndex++
                    }
                    { /* Child Cue */ }
                    return createCueItem({
                      index: `${displayIndex}.${displayChildIndex}`,
                      cueId: child.id,
                      parentId: cue.id,
                      children: undefined,
                      lastSibling: cue.children.length - 1 === childIndex,
                    })
                  })}
                <div
                  className={[
                    'relative transition-[margin,padding]',
                    (draggedCue && ' py-4 px-2 '),
                  ].join(' ')}
                  onDragOver={(e) => onDragOver(e, `group-end-${cue.id}`, cue.id)}
                  onDrop={(e) => onDrop(e, `group-end-${cue.id}`)}
                >
                  {/* Dropzone Indicator */}
                  {draggingGroup
                    ? ''
                    : (
                        <div
                          className={[
                            'absolute pointer-events-none left-[2.5rem] max-w-4xl w-4/5 ml-10 -top-1 border-b-2 h-2 border-blue-500 bg-black duration-300',
                            (draggedOverCue === `group-end-${cue.id}` ? 'opacity-100' : 'opacity-0'),
                          ].join(' ')}
                        >
                        </div>
                      )}
                </div>
              </Fragment>
            )
          } else {
            { /* Standalone Cue */ }
            return createCueItem({
              index: `${displayIndex}`,
              cueId: cue.id,
              parentId: undefined,
              children: undefined,
              lastSibling: false,
            })
          }
        })
      }

      {/* Plan limit alert */}
      <div>
        {cuesLimitExceeded && cues.length > plan.limits.cues
        && (
          <div className="m-6">
            <UpgradeMessage message="Cues limit exceeded for this rundown. Upgrade your account to add and edit more cues." />
          </div>
        )}
      </div>

      <div
        className={[
          'relative py-3 px-2 transition-[margin]',
        ].join(' ')}
        onDragOver={(e) => onDragOver(e, 'end')}
        onDrop={(e) => onDrop(e, 'end')}
      >
        {/* Dropzone Indicator */}
        <div
          className={[
            'absolute pointer-events-none left-[2.5rem] max-w-4xl w-4/5 -top-3 border-b-2 h-4 border-blue-500 bg-black duration-300',
            (draggedOverCue === 'end' ? 'opacity-100' : 'opacity-0'),
          ].join(' ')}
        >
        </div>
      </div>

      <div className="ml-10 mb-5 flex space-x-8">
        { !runner && <PlannedEnd rundown={rundown} />}
        <EndOfShow timezone={rundown.timezone} todDisplayFormat={rundown.settings?.todDisplayFormat} />
        <OverUnder />
      </div>

      {RundownToken.access === ACCESS_WRITE
        ? (
            <div className="ml-10 flex items-center">
              <div className="flex">
                <Button
                  className="text-base !font-normal rounded-r-none tabular-nums"
                  text={`Add ${cueCountToAdd > 1 ? cueCountToAdd : ''} cue${cueCountToAdd > 1 ? 's' : ''}`}
                  icon={faPlus}
                  loading={loading}
                  colour="dark"
                  toolTip={cuesLimitExceeded
                    ? (
                        <>
                          Limit reached
                          <FontAwesomeIcon icon={faBolt} className="text-yellow-500" />
                          {' '}
                          Upgrade for more
                        </>
                      )
                    : ''}
                  onClick={() => handleAddCue()}
                  data-label="create-rundown-button"
                />
                <Menu className="rounded-l-none border-l-2 border-gray-800" icon={faChevronDown}>
                  <MenuItem
                    icon={faHeading}
                    label="Add heading"
                    onClick={() => handleAddCue(CUE_TYPE_HEADING)}
                  />
                  <MenuItem
                    icon={faLayerGroup}
                    label="Add group"
                    onClick={() => handleAddCue(CUE_TYPE_GROUP)}
                  />
                </Menu>
              </div>
              <label htmlFor="addCuesInput" className="ml-2 text-sm space-x-1">
                <input
                  id="addCuesInput"
                  className="px-2 h-9 w-16 bg-transparent focus:outline-none focus:ring rounded border border-white/20"
                  value={cueCountToAdd}
                  onChange={(e) => {
                    const min = 1
                    const max = 30
                    let _val = 1
                    if (e.target.value < min) {
                      _val = 1
                    } else if (e.target.value > max) {
                      _val = max
                    } else {
                      _val = parseInt(e.target.value)
                    }
                    setCueCountToAdd(_val)
                  }}
                  type="number"
                  step={1}
                  min={1}
                  max={30}
                />
                <span className="h-7 inline-block text-gray-600">
                  more cue
                  {cueCountToAdd > 1 && 's'}
                  {cueCountToAdd > 29 && ' 😱'}
                </span>

              </label>
            </div>
          )
        : ''}

      <UpgradeModal
        open={showUpgradeModal}
        setOpen={setShowUpgradeModal}
        onHide={() => setShowUpgradeModal(false)}
        message="You&apos;ve reached your limit for cues on this rundown."
      />

      <RundownColoursModal
        colours={rundown.settings || { cueBackgroundColours: CUE_BACKGROUND_COLORS }}
        open={rundownColoursModalOpen}
        setOpen={setRundownColoursModalOpen}
      />

      <KeyboardNavigationHandler />
    </div>
  )
}

RundownBody.propTypes = {
  rundown: PropTypes.object.isRequired,
  cues: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  cells: PropTypes.array.isRequired,
  runner: PropTypes.object,
  hiddenColumns: PropTypes.array,
  readonly: PropTypes.bool,
  plan: PropTypes.object.isRequired,
}

function CueItemEnd ({
  className,
  timezone,
  time,
  updateTime,
}) {
  return (
    <div className={['flex gap-1', className].join(' ')}>
      {/* Controls Spacer */}
      <div className="w-7 flex-none"></div>
      {/* Time Label */}
      <div className="w-[4rem] flex-none uppercase font-semibold text-gray-300">
        End
      </div>
      {/* Time Display */}
      <div className="flex-grow flex items-center gap-2 pl-2 text-sm">
        <TimeInput
          timezone={timezone}
          time={time}
          updateTime={({ time }) => updateTime(time)}
        />
      </div>
    </div>
  )
}

CueItemEnd.propTypes = {
  className: PropTypes.string,
  timezone: PropTypes.string,
  time: PropTypes.instanceOf(Date).isRequired,
  updateTime: PropTypes.func.isRequired,
}

function LeftToAllocate ({ className, timeLeftToAllocate }) {
  const leftMs = floorSeconds(timeLeftToAllocate)

  return (
    <div className={['flex gap-1', className].join(' ')}>
      {/* Controls Spacer */}
      <div className="w-7 flex-none"></div>
      {/* Content */}
      <div className="flex-grow text-gray-600 flex items-center gap-2">
        <FontAwesomeIcon icon={faExclamationCircle} />
        {Math.abs(leftMs) <= 1000
          ? (
              <span>You&apos;re running on time 🎉</span>
            )
          : (leftMs > 0
              ? (
                  <span>
                    You will finish
                    {formatDurationHuman(timeLeftToAllocate)}
                    {' '}
                    early
                  </span>
                )
              : (
                  <span>
                    You are running
                    {formatDurationHuman(timeLeftToAllocate)}
                    {' '}
                    late
                  </span>
                ))}
      </div>
    </div>
  )
}

LeftToAllocate.propTypes = {
  className: PropTypes.string,
  timeLeftToAllocate: PropTypes.number.isRequired,
}
