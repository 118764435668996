import ModalWrapper from './ModalWrapper.jsx'
import TimezoneSelect from '../interactives/TimezoneSelect.jsx'
import CurrentTimeOfDay from '../rundown/CurrentTimeOfDay.jsx'
import formatTimezone from '../../utils/formatTimezone.js'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { updateRundown } from '../../firestore.js'

export default function RundownTimezoneModal ({
  rundownId,
  timezone,
  open,
  setOpen,
}) {
  const [rundownTz, setRundownTz] = useState(undefined)
  const [browserTz, setBrowserTz] = useState(undefined)
  const [loading, setLoading] = useState(false)

  function onShow () {
    setLoading(false)
    setRundownTz(timezone)
    setBrowserTz(Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'UTC')
  }

  async function onSave () {
    if (!rundownTz) return setOpen(false)

    setLoading(true)
    await updateRundown(rundownId, { timezone: rundownTz })
    setLoading(false)
    setOpen(false)
  }

  return (
    <ModalWrapper
      title="Select Timezone"
      open={[open, setOpen]}
      onShow={onShow}
      working={loading}
      buttons={[
        {
          text: 'Cancel',
          onClick: () => setOpen(false),
        },
        {
          icon: faCheck,
          text: 'Confirm',
          onClick: () => onSave(),
        },
      ]}
    >
      <div className="font-light space-y-6">
        <TimezoneSelect
          value={rundownTz}
          setValue={setRundownTz}
        />

        <div>
          <p className="text-neutral-400">
            {rundownTz
              ? (
                  <>
                    Your rundown timezone is
                    {' '}
                    <strong>{ formatTimezone(rundownTz, 'city') }</strong>
                    {' '}
                    (
                    { formatTimezone(rundownTz, 'long') }
                    ,
                    {' '}
                    { formatTimezone(rundownTz, 'offset') }
                    ),
                    {' '}
                    <CurrentTimeOfDay timezone={rundownTz} omitSeconds />
                    {' '}
                    current time.
                  </>
                )
              : (
                  <>You have not chosen a timezone for this rundown.</>
                )}
          </p>

          {browserTz
            ? (
                <p className="text-neutral-400">
                  Your browser is in
                  {' '}
                  <strong>{ formatTimezone(browserTz, 'city') }</strong>
                  {' '}
                  (
                  { formatTimezone(browserTz, 'long') }
                  ,
                  {' '}
                  { formatTimezone(browserTz, 'offset') }
                  ),
                  {' '}
                  <CurrentTimeOfDay timezone={browserTz} omitSeconds />
                  {' '}
                  current time.
                </p>
              )
            : (
                <>Your browser timezone is undefined.</>
              )}
        </div>
      </div>
    </ModalWrapper>
  )
}

RundownTimezoneModal.propTypes = {
  rundownId: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}
