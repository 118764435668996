import ModalWrapper from './ModalWrapper.jsx'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { emailGuest } from '../../firestore.js'
import { useSetAtom } from 'jotai'
import { toastAtom } from '../../store/toast.store.js'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function EmailLinkModal ({ open, setOpen, url = null }) {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const addToast = useSetAtom(toastAtom)

  async function onSendEmail(e) {
    e.preventDefault()
    setLoading(true)
    try {
      await emailGuest(email, url)
      addToast({ title: `Link has been sent to ${email}`, type: 'success' })
      setEmail('')
    } catch {
      addToast({ title: `We were unable to send the email. Please try again`, type: 'fail' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <ModalWrapper
      title="Email link to guest"
      open={[open, setOpen]}
      width={500}
      buttons={[{
        text: 'Close',
        colour: 'dark',
        onClick: () => setOpen(false),
      }]}
      onShow={() => setEmail('')}
    >
      <form className="px-6 flex items-end justify-center gap-2 w-full" onSubmit={onSendEmail}>
        <div className="mt-6 space-y-2">
          <p className="text-gray-400 font-light">Email address</p>
          <div className="flex gap-2">
            <div
              className={[
                'flex-grow rounded h-9 text-xs',
                (loading ? 'bg-animated from-gray-800 to-gray-600 text-white/70' : 'bg-gray-800'),
              ].join(' ')}
            >
              <input
                value={email}
                type="email"
                disabled={loading}
                onChange={(event) => setEmail(event.target.value)}
                className="bg-transparent h-9 w-full px-3 focus:outline-none focus:ring rounded"
                placeholder="guest@email.com"
                required
                autoFocus
              />
            </div>
            <button
              type="submit"
              className={[
                'rounded text-sm h-9 leading-9 px-3 focus:outline-none focus:ring ',
                (loading ? 'bg-animated from-gray-800 to-gray-600 text-white/70' : 'bg-gray-800 hover:bg-gray-700'),
              ].join(' ')}
            >
              <FontAwesomeIcon icon={faEnvelope} size="sm" className="mr-2" />
              <span>Send</span>
            </button>
          </div>
        </div>
      </form>
    </ModalWrapper>
  )
}

EmailLinkModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  url: PropTypes.string,
}
